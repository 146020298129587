import { instance } from '@/utils/http.js'

// 获取能力标签数据
export const getTagList = function (params) {
  return instance({
    url: '/api/v2/capability/lst',
    method: 'get',
    params
  })
}


// 创建/修改能力数据
export const saveTag = function (data) {
  return instance({
    url: '/api/v2/capability/save_capability',
    method: 'post',
    data
  })
}


// 删除能力数据
export const delTag = function (data) {
  return instance({
    url: '/api/v2/capability/del',
    method: 'post',
    data
  })
}

