<template>
  <div>
    <el-dialog :title="text+'能力素养'"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               width="400px"
               :before-close="handleClose">
      <el-form :model="form"
               :rules="rules"
               v-if="dialogVisible"
               ref="formRef"
               label-width="90px">
        <el-row :gutter="10">
          <el-col :span="24"
                  v-if="form.level == 2">
            <el-form-item label="关键能力:"
                          prop="capabilityId">
              <el-select style="width: 100%"
                         v-model="form.capability_parent_id"
                         clearable
                         placeholder="请选择">
                <el-option v-for="(item, index) in capabilitylist"
                           :key="'capability-' + index"
                           :label="item.capabilityName"
                           :value="item.capabilityId"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="科目:"
                          prop="subject_id">
              <el-select style="width:100%"
                         v-model="form.subject_id"
                         clearable
                         placeholder="请选择">
                <el-option v-for="item in subjectList"
                           :key="item.subject_id"
                           :label="item.subject_name"
                           :value="item.subject_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="名称"
                          prop="capability_name">
              <el-input placeholder="请输入"
                        v-model="form.capability_name"
                        style="width:100%" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="排序"
                          prop="serial_no">
              <el-input placeholder="请输入"
                        v-model="form.serial_no"
                        style="width:100%" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="状态">
          <el-switch v-model="form.status"
                     :active-value="1"
                     :inactive-value="0"
                     active-text="显示"
                     inactive-text="隐藏"
                     active-color="#409EFF"
                     inactive-color="#DBDFE6">
          </el-switch>
        </el-form-item>

      </el-form>

      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="save">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

import { saveTag } from '@/api/abilityTag.js'
import { getAllSubject } from '@/api/subject.js'
export default {
  data () {
    return {
      props: {
        subject_id: {
          type: String,
          default: ''
        }
      },
      dialogVisible: false,
      form: {
        serial_no: 0,
        status: 1
      },
      rules: {
        capability_name: [
          { required: true, message: '请输入关键能力名称', trigger: 'change' },
        ],
        subject_id: [
          { required: true, message: '请选择科目', trigger: 'change' },
        ],
        serial_no: [
          { required: true, message: '请输入排序', trigger: 'change' },
        ],
      },
      subjectList: [],
      text: '',
      capabilitylist: []
    }
  },
  mounted () {
    this.getSubjectList()
    this.getTList()
  },

  methods: {
    handleClose () {
      this.dialogVisible = false
      this.$refs.formRef.resetFields()
    },
    async getSubjectList () {
      const { data } = await getAllSubject()
      this.subjectList = data.list
    },
    async getTList (subject_id) {
      let params = {
        subject_id: subject_id
      };
      return this.$http({
        url: "/api/v2/capability/lst",
        method: 'get',
        params
      }).then(res => {
        this.capabilitylist = res.data.list.map(item => {
          if (item.capability_name && item.capability_id) {
            const capabilityName = item.capability_name;
            const capabilityId = item.capability_id;
            return { capabilityName, capabilityId }
          }
        }).filter(item => item);
        return this.capabilitylist
      })
    },

    save () {
      this.$refs.formRef.validate(async val => {
        if (!val) return
        await saveTag({
          level: this.form.level,
          capability_parent_id: this.form.capability_parent_id,
          capability_id: this.form.capability_id,
          capability_name: this.form.capability_name,
          subject_id: this.form.subject_id,
          serial_no: this.form.serial_no,
          status: this.form.status,
        })
        this.$notify({
          title: '提示',
          message: '操作成功!',
          type: 'success'
        });
        this.handleClose()
        if (this.text == '新增' && this.form.level !== 2) {
          console.log(55);
          this.$parent.search()
        } else {
          this.$parent.refresh()
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
::v-deep .el-dialog__footer {
  text-align: center;
}
.toopic {
  font-size: 12px;
  line-height: 14px;
  span {
    margin-left: -12px;
    display: inline-block;
    width: 100%;
    width: 305px;
    color: #ff8383;
    transform: scale(0.9);
  }
}

::v-deep .el-dialog {
  border-radius: 15px;
}
.el-tabs {
  margin-left: 20px;
}
::v-deep .el-tabs__nav-wrap::after {
  background: none;
}
</style>